import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout/siteLayout"
import SEO from "../components/layout/seo"
import PageHeader from "../components/pageHeader"
import ContentBodyText from "../components/contentBody/text"

import "./jobPost.scss"
import NavigationArrowWhiteIcon from "../images/navigation_arrow--white.svg"

const ContentBlocks = {
  Craft_ContentBodyText: ContentBodyText,
}

const JobPost = ({ data, location }) => {
  const { title, heroImage, color, contentBody } = data.craft.entry
  const { email } = data.craft.globals.careers
  return (
    <Layout location={location}>
      <Helmet bodyAttributes={{ class: "post career-details" }} />
      <SEO title={`${title} | Our Culture`} location={location} />
      <PageHeader heroImage={heroImage} color={color}>
        <h1>{title}</h1>
      </PageHeader>

      <section className="layout__section layout__article">
        <div className="layout__main">
          <div className="layout__inner layout__inner--full-width">
            {contentBody.length > 0 &&
              contentBody.map(block => {
                const BlockComponent = ContentBlocks[block.__typename]
                return (
                  <BlockComponent
                    key={`contentBlock-${block.__typename.toLowerCase()}-${
                      block.id
                    }`}
                    {...block}
                  />
                )
              })}
          </div>

          <div className="btns-group">
            <div className="btns-group__left-btns">
              <Link
                to="/culture#careers"
                className="btn btn--back btn--solid btn--accent"
              >
                <NavigationArrowWhiteIcon alt="Arrow" /> More Jobs
              </Link>
            </div>
            <div className="btns-group__right-btns">
              <a
                href={`mailto:${email}?subject=${title}`}
                target="_top"
                className="btn btn--accent"
              >
                Apply
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const getOpenMicPostQuery = graphql`
  query($slug: String!) {
    craft {
      globals {
        careers {
          ... on Craft_Careers {
            email: careersResponseEmail
          }
        }
      }
      entry(section: [jobs], slug: $slug) {
        ... on Craft_JobsJobOpening {
          title
          heroImage {
            ... on Craft_HeroVolume {
              id
              filename
              focalPoint {
                x
                y
              }
              title
              heroImageSize_FieldData {
                selected {
                  value
                }
              }
              heroImageSizeModifier_FieldData {
                selected {
                  label
                }
              }
              height
              width
            }
          }
          color
          contentBody {
            ... on Craft_ContentBodyText {
              __typename
              id
              richText {
                content
              }
            }
          }
        }
      }
    }
  }
`

export default JobPost
